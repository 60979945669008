<template>
  <div id="app">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    <Header />
    <Main />
    <Footer />
    <!-- <router-view/> -->
  </div>
</template>
<script>
import Header from "@/components/header/header";
import Main from "@/components/Content";
import Footer from "@/components/footer/footer";
import "./assets/css/index.css";
export default {
  components: {
    Header,
    Main,
    Footer,
  }
}
</script>
<style>
</style>
