<template>
  <div class="home">
    <el-backtop :bottom="60"></el-backtop>
    <div class="home-back">
      <div class="home-main">
        <img src="@/assets/images/mphoto1.png" />
        <el-menu
          :default-active="activeIndex2"
          class="el-menu"
          mode="horizontal"
          @select="handleSelect"
          background-color="#0d1325"
          text-color="#6e7177"
          active-text-color="#f74c38"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">软件</el-menu-item
          ><el-menu-item index="3">大数据</el-menu-item
          ><el-menu-item index="4">服务</el-menu-item>
          <el-menu-item index="5">关于</el-menu-item>
          <el-menu-item index="6">联系我们</el-menu-item>
        </el-menu>
        <div class="banner">
          <div>网站建设、大数据、影像服务<br /></div>
          遥图秉承“信誉、质量、服务”的经营理念，<br />重品牌、讲诚信，为顾客提供最佳服务，为社会提供最优产品。
          <span></span>
        </div>
      </div>
    </div>
    <div class="serve">
      <div class="serve-t">产品与服务</div>
      <span>遥图致力为客户提供创新、多元、满意的服务。</span>
      <div class="card">
        <el-card class="box-card">
          <div><img src="@/assets/images/service-p1.png" width="100" /></div>
          <div>
            <span class="service-font">产品服务</span>
          </div>
          <div class="service-content">
            <span
              >草畜预警平台实现实时移动PC联动模式、<br />草原评价模型计算系统、内蒙古草地生态评价系统、<br />草原非生物灾害数据管理系统等</span
            >
          </div>
          <div>
            <el-button class="service-button" type="primary"
              >查看详情</el-button
            >
          </div>
        </el-card>
        <el-card class="box-card">
          <div><img src="@/assets/images/service-p2.png" width="100" /></div>
          <div>
            <span class="service-font">大数据Web开发</span>
          </div>
          <div class="service-content">
            <span
              >乌海及周边污染源成因及对策大数据分析平台、<br />草畜平衡定量监测预警平台</span
            >
          </div>
          <div style="margin-top: 21px">
            <el-button class="service-button" type="primary"
              >查看详情</el-button
            >
          </div>
        </el-card>
        <el-card class="box-card">
          <div><img src="@/assets/images/service-p3.png" width="100" /></div>
          <div>
            <span class="service-font">影像服务</span>
          </div>
          <div class="service-content">
            <span
              >内蒙古全自治区级自然保护地人类活动分布、<br />2015-2020年呼伦湖影像图、<br />内蒙古大冷山自治区森林公园等</span
            >
          </div>
          <div>
            <el-button class="service-button" type="primary"
              >查看详情</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
    <div class="product">
      <div class="product-t">产品服务</div>
      <span
        >草原评价模型计算系统、内蒙古生态评价系统<br />草畜预警平台实现实时移动PC联动模式、草原非生物灾害数据管理系统等</span
      >
      <div class="picture">
        <el-row>
          <el-col :span="17">
            <el-row>
              <el-col :span="12" style="height: 350px">
                <img
                  src="@/assets/images/prodect-p1.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </el-col>
              <el-col
                :span="6"
                style="height: 350px; align-content: flex-start; display: flex"
              >
                <img
                  src="@/assets/images/prodect-p5.jpg"
                  style="width: 98%; height: 98%"
                  alt=""
                />
              </el-col>
              <el-col
                :span="6"
                style="
                  height: 350px;
                  align-content: flex-end;
                  display: flow-root;
                "
              >
                <img
                  src="@/assets/images/prodect-p6.jpg"
                  style="width: 98%; height: 98%"
                  alt=""
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" style="height: 350px">
                <img
                  src="@/assets/images/prodect-p3.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </el-col>
              <el-col
                :span="6"
                style="height: 350px; align-content: flex-start; display: flex"
              >
                <img
                  src="@/assets/images/prodect-p7.jpg"
                  style="width: 98%; height: 98%; margin-top: 7px"
                  alt=""
                />
              </el-col>
              <el-col
                :span="6"
                style="
                  height: 350px;
                  align-content: flex-end;
                  display: flow-root;
                "
              >
                <img
                  src="@/assets/images/prodect-p8.jpg"
                  style="
                    width: 98%;
                    height: 98%;
                    margin-top: 7px;
                    margin-left: 5px;
                  "
                  alt=""
                />
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7" style="height: 700px">
            <el-row>
              <el-col :span="24" style="height: 250px">
                <img
                  src="@/assets/images/prodect-p2.jpg"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" style="height: 250px">
                <img
                  src="@/assets/images/prodect-p9.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" style="height: 200px">
                <img
                  src="@/assets/images/prodect-p4.jpg"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="develop">
      <div class="develop-t">大数据开发</div>
      <br />
      <span
        >大数据技术，是指从各种各样类型的数据中，快速获得有价值信息的能力。<br /><br />
        适用于大数据的技术，包括大规模并行处理数据库，数据挖掘电网，分布式文件系统，分布式数据库，云计算平台，互联网，和可扩展的存储系统。
      </span>

      <div class="develop-bg">
        <el-card class="develop-card">
          <el-row>
            <el-col :span="10">
              <el-row :gutter="2">
                <el-col :span="11" style="height: 200px"
                  ><img src="@/assets/images/develop-p1.png" alt=""
                /></el-col>
                <el-col :span="11" :offset="2" style="height: 200px"
                  ><img src="@/assets/images/develop-p2.png" alt=""
                /></el-col>
              </el-row>
              <el-row :gutter="2">
                <el-col :span="24" style="height: 350px"
                  ><img src="@/assets/images/develop-p3.png" alt="" /></el-col
              ></el-row>
            </el-col>
            <el-col :span="12" :offset="1"
              ><el-row :gutter="2">
                <el-col :span="24" style="height: 350px"
                  ><img src="@/assets/images/develop-p4.png" alt=""
                /></el-col>
              </el-row>
              <el-row :gutter="2">
                <el-col :span="11" style="height: 200px"
                  ><img src="@/assets/images/develop-p5.png" alt=""
                /></el-col>
                <el-col :span="11" :offset="2" style="height: 200px"
                  ><img
                    src="@/assets/images/develop-p6.png"
                    alt="" /></el-col></el-row
            ></el-col>
          </el-row>
        </el-card>
        <div style="position: absolute; margin-top: 660px; margin-left: 73%">
          <img src="@/assets/images/develop-p7.png" alt="" />
        </div>
        <div class="ImpactService">
          <div class="develop-t">影像服务</div>
          <br />
          <span
            >公司一直致力于遥感应用工作。对andset、QuickBird、worldview、NOAA、国家资源卫星和国产高分卫星数据深入的分析与应用，<br />
            并形成了一套成熟的处理流程，在工作中提高了效率和质量。
          </span>
          <div class="ImpactService-photo">
            <div class="ImpactService-photo1">
              <img src="@/assets/images/ImpactService-p1.png" alt="" />
            </div>
            <div class="ImpactService-photo1">
              <img src="@/assets/images/ImpactService-p2.png" alt="" />
            </div>
            <div class="ImpactService-photo2">
              <img src="@/assets/images/ImpactService-p3.png" alt="" />
            </div>
            <div class="ImpactService-photo2">
              <img src="@/assets/images/ImpactService-p4.png" alt="" />
            </div>
          </div>
        </div>
        <div class="about">
          <div class="about-t">关于我们</div>
          <br />
          <span
            >我公司成立于2013年，自公司成立以来与科研院所、大学等单位进行深入的技术交流与合作，为团队积累了丰富的遥感及地理信息系统建设的经验，<br />
            具备了基于地理信息、遥感、全球定位系统、综合信息管理系统等系统综合研发和集成的能力。公司秉承“信誉、质量、服务”的经验理念，重品牌、<br />
            将诚信，为顾客提供最佳服务，为社会提供最优产品。<br />
            2022年6月23日经过资信、诚信、服务、守信、经营示范、口碑等多方面考察荣获“3A”级企业称号。
          </span>
          <div style="height: 320px; background-color: rgb(34, 34, 34)">
            <img src="@/assets/images/develop-p8.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex2: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
