<template>
  <div>
    <keep-alive>
      <component :is="view"></component>
    </keep-alive>
  </div>
</template>

<script>
import home from "@/views/Home";
import remotesensing from "@/views/RemoteSensing";
export default {
  data() {
    return {
      view: 'home',
    }
  },
  components: {
    home,
    remotesensing,
  }
}
</script>
