<template>
  <div class="footer">
    <div>
      <span>联系我们</span>
      <span style="top: 9px; position: relative">CONTACT</span>
    </div>
    <div>
      <div>内蒙古遥图信息技术有限责任公司</div>

      <div>
        <span>地点：内蒙古呼和浩特市新华大街老缸房街3号</span>
        <span>电话：0471-492 1597</span>
        <span>邮编：011800</span>
      </div>
    </div>
    <div style="text-align: center; margin-left: -1198px; margin-top: 103px">
      <a
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=15010502001987"
        target="_blank"
        style="color: #fff"
        ><img src="@/assets/images/beian.png" width="15" />蒙公网安备
        15010502001987号</a
      ><a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        style="margin-left: 20px; color: #fff"
        >蒙ICP备20001524号-1</a
      ><span style="margin-left: 20px">Copyright©2013-2023 内蒙古遥图信息技术有限责任公司 版权所有 </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.footer {
  font-size: 16px;
  background-color: #546fb4;
  display: flex;
  color: #ffffff;
}
.footer > div:nth-child(1) {
  width: 30%;
  text-align: center;
  padding-top: 31px;
}
.footer > div:nth-child(1) span:nth-child(1) {
  display: block;
  color: #ffffff;
  /* font-size: 23px; */
  margin-bottom: 10px;
}
.footer > div:nth-child(2) div:nth-child(1) {
  /* font-size: 23px; */
  color: #ffffff;
  margin-bottom: 15px;
  margin-top: 30px;
}
.footer > div:nth-child(2) div:nth-child(2) span {
  display: inline-block;
  /* font-size: 17px; */
  margin-right: 165px;
}
</style>