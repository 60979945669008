<template>
  <div>
    <div class="header">
      <div class="hlogo">
        <img src="../../assets/images/hlogo.png"  alt="" />
      </div>
      <div class="htitle"><a>内蒙古遥图信息技术有限责任公司官方网站</a></div>
      <div class="hetitle"><a>Inner Mongolia Yaotu Information Technology Co.,Ltd</a></div>
      <!-- <div class="htitle">
        <img src="../../assets/images/htitle.png" width=" 450" alt="" />
      </div>
      <div class="hetitle">
        <img src="../../assets/images/hetitle.png" width=" 500" alt="" />
      </div> -->
      <div class="nav">
        <div class="search"><span>搜索</span></div>
        <div class="phone">
          <img src="../../assets/images/hphone.png" width=" 70" alt="" />
        </div>
         <div class="phoneinfo">
          <span>咨询热线</span>
          <span class="phoneinfo-num">0471-492 1597</span>
        </div>
         <div class="line1"></div>
          <div class="line2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>